DS = Ember;

// Avoid unnecessary container registrations from occuring in ember-test-helpers package
DS._setupContainer = function() {};

TastypieRESTAdapter = Ember.RESTAdapter.extend({
	buildURL: function(klass, id) {
		var url = Ember.get(klass, 'url') || nameToUrl(klass.toString());
		if (Ember.isArray(id)) {
			id = 'set/' + id.join(';');
		}
		if (!Ember.isEmpty(id)) {
			return url + id + "/";
		}
		return url;
	},
  MIN_CHUNK_SIZE: 30,
  MAX_IDS_PER_SET: 300, // max url is 1900 assume 80 for base url and 6 for each id (5 digits and ;) = 303
  MAX_CONCURRENT_CALLS: 3, // NOTE was 10 but reduced as the WAF has broken SPDY
  findMany: function(klass, records, ids) {
    var baseUrl = this.buildURL(klass);
    var length = ids.length;
    var idGroups = [];
    // Basic concept it to split into upto 10 concurrent calls to
    // parallelize the processing on the server and the download
    // we have a miniumum to unsure it's worth the servers time
    // and a max for the degenerate case where our url would be over 1900 chars
    var chunkSize = Math.min(Math.max(Math.ceil(length / this.MAX_CONCURRENT_CALLS), this.MIN_CHUNK_SIZE), this.MAX_IDS_PER_SET);
    for (var i = 0; i < length; i += chunkSize) {
      idGroups.push(ids.slice(i, i + chunkSize));
    }

    var promises = idGroups.map(function(idGroup) {
      var url = baseUrl + "set/" + idGroup.join(";") + "/";
      return this.ajax(url);
    }, this);
    return Ember.RSVP.all(promises).then(function(jsons) {
			var data = jsons.reduce(function(combined, group) {
				return combined.concat(group['objects']);
			}, []);
			records.load(klass, data);
			return records;
		});
	},
	/*
  findMany: function(klass, records, ids) {
		var url = this.buildURL(klass, ids);

		return this.ajax(url).then(function(data) {
			this.didFindMany(klass, records, data);
			return records;
		}.bind(this));
	},
  didFindMany: function(klass, records, data) {
		var collectionKey = Ember.get(klass, 'collectionKey');
		var dataToLoad = collectionKey ? Ember.get(data, collectionKey) : data;

		records.load(klass, dataToLoad);
  },
	*/
	ajax: function(url, params, method, settings) {
		var proxied_resources = this.get('proxied_resources');
		return this._ajax(url, params, (method || "GET"), settings).then(function(base) {
			var promises = proxied_resources.map(function(proxyMapping) {
				var proxied_resource = proxyMapping[0];
				var related_resources = proxyMapping[1];
				// Guards to return early if we don't need to do the merging:
				if (!base) {
					return;
				}
				if (url != '/api/batch' && !url.match('^/api/' + proxied_resource + '/')) {
					return;
				}
				// Normalise the different types of tastypie resources into a arr of objects:
				var arr;
				if (base['results'] !== undefined) {
					var clientIds = params['requests'].filter(function(req) {
						return req['url'].match('^/api/' + proxied_resource + '/(?:\\d+/)?$');
					}).mapProperty('clientId');
					arr = base['results'].filter(function(res) {
						return clientIds.indexOf(res['clientId']) !== -1;
					}).mapProperty('data');
				}
				else if (base['objects'] !== undefined) {
					arr = base['objects'];
				}
				else if (Ember.isArray(base)) {
					arr = base;
				}
				else {
					arr = [base];
				}
				var ids = arr.mapProperty('id');
				if (Ember.isEmpty(ids)) {
					return;
				}
				// Get the related resources that we're going to merge
				var length = ids.length;
				var idGroups = [];
				// Basic concept it to split into upto 10 concurrent calls to
				// parallelize the processing on the server and the download
				// we have a miniumum to unsure it's worth the servers time
				// and a max for the degenerate case where our url would be over 1900 chars
				var chunkSize = this.MAX_IDS_PER_SET;
				for (var i = 0; i < length; i += chunkSize) {
					idGroups.push(ids.slice(i, i + chunkSize));
				}
				var groupUrls = idGroups.map(function(idGroup) {
					return related_resources.map(function(resource) {
						return '/api/' + resource + '/set/' + idGroup.join(';') + '/';
					});
				});
				var urls = groupUrls.reduce(function(acc, urls_for_group) {
					return acc.concat(urls_for_group);
				}, []);
				var urlsPromises = urls.map(function(url) {
					return this._ajax(url, null, 'GET', null);
				}.bind(this));
				// wait for related ajax calls
				return Ember.RSVP.all(urlsPromises).then(function(related) {
					// Here we merge in the new data from the related resources into the base
					// (Note we use arr to smoove over the case were base is an array or not.)
					// FYI Ember.merge is side-effect'y so we don't need to replace base
					// Version 2
					var arrById = arr.reduce(function(acc, obj) {
						var id = obj['id'];
						acc[id] = obj;
						return acc;
					}, {});
					related.forEach(function(relArr) {
						relArr['objects'].forEach(function(rel) {
							var id = rel['id'];
							var obj = arrById[id];
							if (obj) {
								Ember.merge(obj, rel);
							}
						});
					});
				});
			}.bind(this)).filter(identity);
			// optimisation (Ember.RSVP.all can take a empty array seems wasteful when that's the most common case)
			if (promises.length === 0) {
				return base;
			}
			return Ember.RSVP.all(promises).then(function() {
				// make sure the promise return the same as it would have without the merging
				return base;
			});
		}.bind(this));
	},
	/**
	 * DO NOT ADD MORE PROXIED RESOURCES HERE (contact glynn@zenefits.com if you believe you need to)
	 *
	 * This is a hack to handle the two core objects that have a lot of reverse relationships from the spokes.
	 * The code above in ajax: is the implementation of the merging.
	**/
	"proxied_resources": [
		['all_employee', [
			'all_employee_hr_proxy',
			'all_employee_payroll_scraping_proxy',
			'all_employee_payroll_proxy',
			'all_employee_health_proxy',
			'all_employee_aux_proxy',
			'all_employee_ta_proxy',
			'all_employee_pto_proxy',
			'all_employee_international_proxy',
		]],
		['company', [
			'company_hr_proxy',
			'company_payroll_proxy',
			'company_payroll_scraping_proxy',
			'company_health_proxy',
			'company_aux_proxy',
			'company_ta_proxy',
			'company_pto_proxy',
			'company_international_proxy',
			'company_stockoption_proxy',
		]],
	]
});

Ember.Model.reopenClass({
  adapter: TastypieRESTAdapter.create(),
	collectionKey: 'objects',
	createRecord: Ember.aliasMethod('create'),
	_cacheReference: function(reference) {
		this._super(reference);
		// add another cache as ember-model doesn't cache the new objects until they're saved
		// (probably because they don't have a ID yet?)
		if (!this._clientIdCache) { this._clientIdCache = {}; }
		this._clientIdCache[reference.clientId] = reference;
	},
	unload: function(record) {
		this._super(record);
		delete this._clientIdCache[record._reference.clientId];
	},
	clearCache: function() {
		this._super();
		this._clientIdCache = undefined;
	},
	// emulate ember-data filter function
	_filter: function(params, filter, container) {
    var records = Ember.FilteredRecordArray.create({
			modelClass: this,
			_query: params,
			filterFunction: filter,
			filterProperties: ['*'],
			container: container,
		});
    this.adapter.findQuery(this, records, params);
    return records;
	},
});

DidLoadDeferred = Ember.Mixin.create({
	init: function() {
		this._super.apply(this, arguments);
		this.setupPromise();
	},
	setupPromise: function() {
		if (this.get('_hasPendingPromise')) {
			return;
		}
		this.set('_deferred', Ember.RSVP.defer('Ember: DeferredMixin - ' + this));
		this.set('_hasPendingPromise', true);
		this.one('didLoad', this, function() {
			Ember.run(this, '_resolve', this);
		});

		this.one('becameError', this, function() {
			Ember.run(this, '_reject', this);
		});

		if (this.get('isLoaded')) {
			this.trigger('didLoad');
		}
	},
	then: function(resolve, reject, label) {
		var deferred, promise, entity;

		entity = this;
		deferred = this.get('_deferred');
		promise = deferred.promise;

		function fulfillmentHandler(fulfillment) {
			if (fulfillment === promise) {
				return resolve(entity);
			} else {
				return resolve(fulfillment);
			}
		}

		return promise.then(resolve && fulfillmentHandler, reject, label);
	},
	_resolve: function(value) {
		var deferred, promise;

		this.set('_hasPendingPromise', false);
		deferred = this.get('_deferred');
		promise = deferred.promise;

		if (value === this) {
			deferred.resolve(promise);
		} else {
			deferred.resolve(value);
		}
	},
	_reject: function(value) {
		this.set('_hasPendingPromise', false);
		this.get('_deferred').reject(value);
	},
});
Ember.Model.reopen(DidLoadDeferred, {
	save: function() {
		var savingPromise = this._super();
		this.set('savingPromise', savingPromise);
		return savingPromise;
	},
	then: function(resolve, reject, label) {
		var loadingPromise = this._super(resolve, reject, label);
		var savingPromise = this.get('savingPromise')
		if (!savingPromise) {
			return loadingPromise;
		}
		return savingPromise.then(function() {
			return loadingPromise;
		});
	},
});
Ember.RecordArray.reopen(DidLoadDeferred);
Ember.ManyArray.reopen({
	then: function(resolve, reject, label) {
		return Ember.RSVP.all(this.toArray()).then(function() {
			return resolve(this);
		}.bind(this), reject, label);
	},
});

Ember.Model.reopen({
	id: Ember.attr('id'),
	rollback: function() {
		if (this.get('isNew')) {
			this.deleteRecord();
			return new Ember.RSVP.Promise(function(resolve, reject) { resolve(this) }.bind(this));
		}
		else {
			return this.revert();
		}
	},
	unloadRecord: function() {
		this.constructor.unload(this);
	},
	isDirty: function() {
		return (this.get('isModified') || this.get('isNew') || this.get('isDeleted')) && !this.get('isDead');
	}.property('isModified', 'isNew', 'isDeleted', 'isDead'),
	// HACKHACK we'd want to get rid of this over time
	transaction: Ember.computed(function() {
		var customTransaction = this.get('_transaction');
		if (customTransaction) return customTransaction;
		return {
			"commit": function() {
				return App.store.commit();
			},
			"rollback": function() {
				return App.store.rollback();
			},
		};
	}),
});

Ember.Model.dataTypes['boolean'] = {
  deserialize: function(string) {
		return (string === true || string === 'true' || string === 'True');
  },
  serialize: function (bool) {
		return !!bool;
  },
};
Ember.Model.dataTypes['id'] = {
	deserialize: function(string) {
		var id = parseFloat(string);
		return !isNaN(id) ? id : string;
	},
	serialize: function (id) {
		return id;
	},
};
Ember.Model.dataTypes['date'] = Ember.Model.dataTypes[Date];
Ember.Model.dataTypes['number'] = Ember.Model.dataTypes[Number];
Ember.Model.dataTypes['string'] = {
  deserialize: function(string) {
		return string == null ? null : String(string);
  },
  serialize: function (posString) {
		return posString == null ? null : String(posString);
  },
};

_Transaction = Ember.ArrayProxy.extend({
	init: function() {
		this._super();
		this.set('content', Ember.A());
	},
	createRecord: function(klass, props) {
		var obj = klass.create(Ember.merge({ "_transaction": this }, props));
		this.add(obj);
		return obj;
	},
	commit: function() {
		return Ember.RSVP.all(this.toArray().invoke("save"));
	},
	rollback: function() {
		return Ember.RSVP.all(this.toArray().invoke("rollback"));
	},
	add: function(record) {
		this.get('content').push(record);
	},
});

var nameToUrl = function(type) {
	var parts = type.split(".");
	var name = parts[parts.length - 1];
	var root = name.replace(/([A-Z])/g, '_$1').toLowerCase()
	if (root[0] == '_') {
		root = root.slice(1);
	}
	var url = "/api/" + root + "/";
	return url;
};

_allTypes= null,
Ember.Model.Store.reopen({
	modelFor: function(type) {
		var klass = this._super(type);
		if (klass && !Ember.get(klass, 'url') && !Ember.Model.detect(type)) {
			var url = nameToUrl(type);
			klass.reopenClass({ "url": url });
		}
		return klass;
	},
	transaction: function() {
		return _Transaction.create();
	},
	commit: function() {
		return Ember.RSVP.all(this.allDirtyRecords().invoke("save"));
	},
	rollback: function() {
		return Ember.RSVP.all(this.allDirtyRecords().invoke("rollback"));
	},
	// emulate ember-data filter function
	filter: function(type, params, filter, container) {
    var klass = this.modelFor(type);
		return klass._filter(params, filter, this.container);
	},
	allTypes: function() {
		if (_allTypes) {
			return _allTypes;
		}
		_allTypes = [];
		var namespace = Ember.Namespace.NAMESPACES_BY_ID['App'];
		for (key in namespace) {
			var value = namespace[key];
			if (Ember.Model.detect(value)) {
				_allTypes.push(value);
			}
		}
		return _allTypes;
	},
	allDirtyRecords: function() {
		var records = [];
		var types = this.allTypes()
		for (var i = 0, l = types.length; i < l; i++) {
			var type = types[i];
			var references = type._clientIdCache;
			for (var key in references) {
				var record = references[key].record;
				// GMM for some reason isModified will be true if the record isn't loaded
				if (record && !record.get('isDead') && record.get('isLoaded') && (!record.get('isNew') || !record.get('isSaving')) && (record.get('isModified') || record.get('isNew') || record.get('isDeleted'))) {
					records.push(record);
				}
			}
		}
		return records;
	}
});
